import React, { ReactElement, useLayoutEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';

function TinyMCE({
  onChange,
  value,
  name,
  height,
  setLoading,
  toggleFileImage,
  colorScheme,
}: {
  onChange: (content: string, editor: any) => void;
  value: any;
  initialValue: any;
  name: string;
  height: number;
  toggleFileImage?: () => void;
  setLoading?: (isLoading: boolean) => void;
  colorScheme?: string;
}): ReactElement {
  useLayoutEffect(() => {
    setLoading && setLoading(true);
    return () => {
      setLoading && setLoading(false);
    };
  }, [setLoading]);

  const toolbar = `undo redo | blocks | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | image ${
    toggleFileImage ? '| FileImage' : ''
  } | a11ycheck | formatpainter | permanentpen | checklist | casechange | showcomments |`;
  return (
    <Editor
      onInit={() => {
        setLoading && setLoading(false);
      }}
      textareaName={name}
      value={value}
      init={{
        skin: colorScheme == 'dark' ? 'oxide-dark' : 'oxide',
        autolink_pattern:
          /^(https?:\/\/|ssh:\/\/|ftp:\/\/|file:\/|www\.)(.+)$/i,
        height: height || 500,
        menubar: false,
        plugins: [
          'link',
          'searchreplace visualblocks',
          'paste',
          'image',
          'a11ychecker',
          'formatpainter',
          'permanentpen',
          'checklist',
          'casechange',
          'tinycomments',
          'lists',
          'spellchecker',
          'tinymcespellchecker',
        ],
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author',
        toolbar: toolbar,
        link_title: false,
        spellchecker_active: true,
        forced_root_block_attrs: {
          style: 'margin: 0',
        },
        setup: editor => {
          if (toggleFileImage)
            editor.ui.registry.addButton('FileImage', {
              icon: undefined,
              tooltip: 'File/Image',
              text: 'File/Image',
              onAction: function () {
                toggleFileImage && toggleFileImage();
              },
            });
        },
      }}
      onEditorChange={onChange}
      key={colorScheme}
    />
  );
}

export { TinyMCE };
