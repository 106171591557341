import React, { ReactElement, useState } from 'react';
import { Box } from 'rebass/styled-components';
import { SlidePanel, Alert } from 'ui/patterns';
import { Button, Stack, Checkbox, Dropzone, FormLabel } from 'ui/components';
import * as Icon from 'assets/icons';
import { useUploadCustomTable } from 'hooks';
import { showErrorToast, showSuccessToast } from 'utils/showToast';

type RecordProps = {
  showPanel: boolean;
  togglePanel: () => void;
  accountId?: number;
  lookupId?: number;
  title?: string;
};

export default function ImportCustomRecords({
  showPanel,
  togglePanel,
  accountId,
  lookupId,
  title,
}: RecordProps): ReactElement {
  const [file, setFile] = useState<File>();
  const [isForced, setIsForce] = useState(false);
  // const [isAlertVisible, setIsAlertVisible] = useState(true);
  const [uploadCustomTable, { isLoading: isUploadCustomTableLoading }] =
    useUploadCustomTable();

  function onSubmit() {
    // { account, lookupId, file }
    if (file && accountId && lookupId)
      uploadCustomTable(
        { account: accountId, lookupId: lookupId, file, isForced },
        {
          onSuccess: () => {
            showSuccessToast({
              message: 'Your changes were successfully saved.',
            });
            togglePanel();
            setFile(undefined);
            setIsForce(false);
          },
          onError: (error: any) => {
            showErrorToast({ message: `${error.Message}` });
            setFile(undefined);
            setIsForce(false);
          },
        },
      );
  }

  const addRecordsAction = () => (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          togglePanel();
          setFile(undefined);
          setIsForce(false);
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={onSubmit}
        variant="primary"
        disabled={isUploadCustomTableLoading}
      >
        Save
      </Button>
    </>
  );
  console.log('isForced', isForced);

  return (
    <>
      {/* {showPanel ? ( */}
      <SlidePanel
        title={title ? `Import table - ${title}` : 'Import Table'}
        actions={addRecordsAction()}
        onClose={() => {
          togglePanel();
          setFile(undefined);
          setIsForce(false);
        }}
        open={showPanel}
        size="sm"
      >
        <Stack direction="y" gap={3}>
          {/* {isAlertVisible && ( */}
          <Alert
            variant="danger"
            icon={<Icon.ExclamationCircle />}
            // onClose={() => setIsAlertVisible(!isAlertVisible)}
          >
            Importing a CSV will overwrite the records in this lookup table.
          </Alert>
          {/* )} */}
          <Alert variant="info" icon={<Icon.Info />}>
            The CSV should include a header row and should have the same number
            of columns as this lookup table. For an example of a properly
            formatted CSV, please use the Export button to export this custom
            lookup to CSV.
          </Alert>
          <Box>
            <FormLabel>Import File</FormLabel>
            <Dropzone
              // progress={progress}
              value={file && file.name}
              isLoading={isUploadCustomTableLoading}
              // accountId={accountInfoReturn && accountInfoReturn.Id}
              onChange={(file?: File) => {
                setFile(file);
              }}
            />
          </Box>
          <Checkbox
            key={`key-${title}`}
            label="Force Import"
            name="forceImport"
            checked={isForced}
            handleChange={e => {
              e.stopPropagation();
              setIsForce(!isForced);
            }}
          />
        </Stack>
      </SlidePanel>
      {/* ) : null} */}
    </>
  );
}
