import React, { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { confirmAlert } from 'utils/confirm-alert';
import { Dropdown } from 'ui/patterns';
import { Button } from 'ui/components';
import { ColumnInstance, Row } from 'react-table';
import * as Icon from 'assets/icons';
import { CustomRecords } from 'types/customTables';
import { useDeleteRecordsCustomTable } from 'hooks';
import { showErrorToast, showSuccessToast } from 'utils/showToast';

export default function TableOptionsHeader(props: {
  column: ColumnInstance<CustomRecords>;
  row: Row<CustomRecords>;
  onRecordEditPress?: (row: any) => void;
  masterTableId: number;
}): ReactElement {
  const params = useParams<{
    accountId: string;
  }>();
  const accountId = parseInt(params.accountId);
  const [deleteCustomRecords] = useDeleteRecordsCustomTable();

  const customRecordOptions = [
    {
      value: 'Edit Record',
      icon: <Icon.Edit title="Options" />,
      groupEnd: true,
      action: () =>
        props.onRecordEditPress && props.onRecordEditPress(props.row),
    },
    {
      value: 'Delete Record',
      icon: <Icon.Delete title="Options" />,
      action: () => handleDeleteTable(),
    },
  ];

  const handleDeleteTable = () => {
    deleteCustomRecords(
      {
        accountId,
        tableId: props.masterTableId,
        recordId: props.row.original.Id,
        type: 'confirm',
      },
      {
        onSuccess: (deleteRecordResult: any) => {
          if (deleteRecordResult === false) {
            confirmAlert({
              message: 'Are you sure you want to delete this record?',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    deleteCustomRecords(
                      {
                        accountId,
                        tableId: props.masterTableId,
                        recordId: props.row.original.Id,
                        type: 'delete',
                      },
                      {
                        onSuccess: () => {
                          showSuccessToast({
                            message: 'Record deleted successfully.',
                          });
                        },
                      },
                    );
                  },
                  className: 'pr-btn-primary',
                },
                {
                  label: 'No',
                  onClick: () => {
                    //
                  },
                },
              ],
            });
          }
          if (deleteRecordResult === true) {
            confirmAlert({
              message:
                'Warning! This record is used in a branching question. Please make sure that removal of this entry doesnt cause questions to show unintended. Are you sure you want to delete this record?',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    deleteCustomRecords(
                      {
                        accountId,
                        tableId: props.masterTableId,
                        recordId: props.row.original.Id,
                        type: 'delete',
                      },
                      {
                        onSuccess: () => {
                          showSuccessToast({
                            message: 'Record deleted successfully.',
                          });
                        },
                      },
                    );
                  },
                  className: 'pr-btn-primary',
                },
                {
                  label: 'No',
                  onClick: () => {
                    //
                  },
                },
              ],
            });
          }
        },
        onError: ({ Message }: { Message: string }) => {
          showErrorToast({ message: Message });
        },
      },
    );
  };

  const customTableDropdown = () => (
    <Button size="sm">
      <Icon.DotsVertical title="Options" />
    </Button>
  );

  return (
    <>
      <Dropdown
        trigger={customTableDropdown()}
        menuList={customRecordOptions}
        position="left-top"
        className="dd-listitem-options"
      />
    </>
  );
}
