import create from 'zustand';

type ApplicationThemeStore = {
  colourScheme: string;
  tempContent: string;
  setColourScheme: (val: string) => void;
  setTempContent: (val: string) => void;
};

export const useApplicationThemeStore = create<ApplicationThemeStore>(set => ({
  colourScheme: 'light',
  tempContent: '',
  setColourScheme: (val: string) => {
    set(() => ({
      colourScheme: val,
    }));
  },
  setTempContent: (val: string) => {
    set(() => ({
      tempContent: val,
    }));
  },
}));
