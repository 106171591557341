import React, { useState, ReactElement, useEffect } from 'react';
import { Flex } from 'rebass/styled-components';
import { Button } from 'ui/components';
import { SlidePanel, Wizard } from 'ui/patterns';
import { useGetFormDetails } from 'hooks';
import { SectionTypeInput } from 'types/forms';
import AccountsPanel from './AccountsPanel';
import FormsPanel from './FormsPanel';
import { Questions } from 'types/questions';
import SectionsPanel from './SectionsPanel';
import QuestionsPanel from './QuestionsPanel';

type CopySectionProps = {
  showPanel: boolean;
  togglePanel: () => void;
  copySection?: (section: SectionTypeInput, cb: () => void) => void;
  copyQuestion?: (Q: Questions, cb: () => void) => void;
  isCopyQuestion: boolean;
  allQuestionsInSection?: Array<Questions>;
};

const wizardTabsInitialState = () => [
  {
    id: 1,
    label: 'Account',
    active: true,
    firstEdit: true,
    completed: false,
  },
  {
    id: 2,
    label: 'Forms',
    firstEdit: false,
    completed: false,
    active: false,
  },
  {
    id: 3,
    label: 'Sections',
    firstEdit: false,
    completed: false,
    active: false,
  },
];

const wizardTabsInitialStateForQuestions = () => [
  {
    id: 1,
    label: 'Account',
    active: true,
    firstEdit: true,
    completed: false,
  },
  {
    id: 2,
    label: 'Forms',
    firstEdit: false,
    completed: false,
    active: false,
  },
  {
    id: 3,
    label: 'Sections',
    firstEdit: false,
    completed: false,
    active: false,
  },
  {
    id: 4,
    label: 'Questions',
    firstEdit: false,
    completed: false,
    active: false,
  },
];

export default function CopySection({
  showPanel,
  togglePanel,
  copySection,
  copyQuestion,
  isCopyQuestion,
  allQuestionsInSection,
}: CopySectionProps): ReactElement {
  const [selectedAccountId, setSelectedAccountId] = useState(0);
  const [selectedFormId, setSelectedFormId] = useState(0);
  const [visiblePanel, setVisiblePanel] = useState(0);
  const [wizardState, setWizardState] = useState(
    isCopyQuestion
      ? [...wizardTabsInitialStateForQuestions()]
      : [...wizardTabsInitialState()],
  );
  const [selectedSectionItems, setSelectedSectionItems] = useState<
    Array<SectionTypeInput> | undefined
  >();
  const [selectedQuestionItem, setSelectedQuestionItem] = useState<Questions>();

  useEffect(() => {
    setSelectedAccountId(0);
    setSelectedFormId(0);
    setVisiblePanel(0);
    setSelectedSectionItems([]);
    setSelectedQuestionItem(undefined);
    setWizardState(
      isCopyQuestion
        ? [...wizardTabsInitialStateForQuestions()]
        : [...wizardTabsInitialState()],
    );
  }, [isCopyQuestion, showPanel]);

  const { data: formDetails, isLoading: isFormDetailsLoading } =
    useGetFormDetails(selectedFormId);

  function onClickNext() {
    if (wizardState[visiblePanel]?.completed === true) {
      const wizardStateCopy = [...wizardState];
      wizardStateCopy[visiblePanel].active = false;
      wizardStateCopy[visiblePanel].firstEdit = false;
      wizardStateCopy[visiblePanel + 1].active = true;
      wizardStateCopy[visiblePanel + 1].firstEdit = true;
      setVisiblePanel(visiblePanel + 1);
      setWizardState([...wizardStateCopy]);
    }
  }

  function onClickPrevious() {
    const wizardStateCopy = [...wizardState];
    wizardStateCopy[visiblePanel].active = false;
    wizardStateCopy[visiblePanel].firstEdit = false;
    wizardStateCopy[visiblePanel - 1].active = true;
    setVisiblePanel(visiblePanel - 1);
    setWizardState([...wizardStateCopy]);
  }

  function onClickCancel() {
    setWizardState(
      isCopyQuestion
        ? [...wizardTabsInitialStateForQuestions()]
        : [...wizardTabsInitialState()],
    );
    setSelectedAccountId(0);
    setSelectedFormId(0);
    setVisiblePanel(0);
    setSelectedSectionItems([]);
    setSelectedQuestionItem(undefined);
    togglePanel();
  }

  function onAccountIdSelected(accountId: number) {
    if (selectedAccountId !== accountId) {
      setSelectedFormId(0);
      setSelectedSectionItems([]);
      setSelectedAccountId(accountId);
      const wizardStateCopy = [...wizardState];
      wizardStateCopy[visiblePanel].active = true;
      wizardStateCopy[visiblePanel].completed = true;
      wizardStateCopy.map((item, index) => {
        if (index > visiblePanel) {
          item.active = false;
          item.completed = false;
          item.firstEdit = false;
        }
      });
      setWizardState([...wizardStateCopy]);
    }
  }

  function onFormIdSelected(formId: number) {
    setSelectedFormId(formId);
    setSelectedSectionItems([]);
    const wizardStateCopy = [...wizardState];
    wizardStateCopy[visiblePanel].active = true;
    wizardStateCopy[visiblePanel].completed = true;
    wizardStateCopy.map((item, index) => {
      if (index > visiblePanel) {
        item.active = false;
        item.completed = false;
        item.firstEdit = false;
      }
    });
    setWizardState([...wizardStateCopy]);
  }

  function onSelectSectionItem(sectionItem: SectionTypeInput) {
    setSelectedSectionItems([sectionItem]);
    const wizardStateCopy = [...wizardState];
    wizardStateCopy[visiblePanel].active = true;
    wizardStateCopy[visiblePanel].completed = true;
    wizardStateCopy.map((item, index) => {
      if (index > visiblePanel) {
        item.active = false;
        item.completed = false;
        item.firstEdit = false;
      }
    });
    setWizardState([...wizardStateCopy]);
  }

  function onSave() {
    if (!isCopyQuestion && copySection) {
      copySection(selectedSectionItems, onClickCancel);
    } else if (isCopyQuestion && copyQuestion && selectedQuestionItem) {
      copyQuestion(selectedQuestionItem, onClickCancel);
    }
    // onClickCancel();
  }

  const copySectionActions = () => (
    <>
      <Button onClick={onClickCancel} variant="secondary">
        Cancel
      </Button>
      <Button
        variant="secondary"
        onClick={onClickPrevious}
        disabled={visiblePanel === 0}
      >
        Previous
      </Button>

      {(isCopyQuestion && visiblePanel === 3) ||
      (!isCopyQuestion && visiblePanel === 2) ? (
        <Button
          onClick={onSave}
          disabled={
            (!isCopyQuestion && selectedSectionItems?.length === 0) ||
            (isCopyQuestion && !selectedQuestionItem)
          }
          variant="primary"
        >
          Save
        </Button>
      ) : (
        <Button
          variant="primary"
          onClick={onClickNext}
          disabled={
            !wizardState[visiblePanel].completed ||
            (isCopyQuestion && visiblePanel === 3) ||
            (!isCopyQuestion && visiblePanel === 2)
          }
        >
          Next
        </Button>
      )}
    </>
  );

  const [expandAll, setExpandAll] = useState<Record<number, boolean>>({});
  useEffect(() => {
    const sections = formDetails?.Sections;
    const expandedList: Record<number, boolean> = {};
    sections &&
      sections
        .filter(
          (item: { $type: string; }) =>
            item.$type ===
            'Proteus2.Api.Domain.Surveys.Section, Proteus2.Api.Domain',
        )
        .forEach((item:any) => {
          expandedList[item.Id] = false;
        });
    setExpandAll({ ...expandedList });
  }, [formDetails]);

  function openCloseSection(id: number) {
    const expandedState = { ...expandAll };
    expandedState[id] = expandedState[id] ? false : true;
    setExpandAll({ ...expandedState });
  }

  function selectQuestionItem(Q: Questions) {
    setSelectedQuestionItem({ ...Q });
    const wizardStateCopy = [...wizardState];
    wizardStateCopy[visiblePanel].active = true;
    wizardStateCopy[visiblePanel].completed = true;
    setWizardState([...wizardStateCopy]);
  }

  return (
    <SlidePanel
      title={isCopyQuestion ? 'Copy Question' : 'Copy Section'}
      open={showPanel}
      onClose={() => {
        // togglePanel();
        onClickCancel();
      }}
      size="lg"
      actions={copySectionActions()}
    >
      <Flex height="100%">
        <Wizard tabs={wizardState} />

        <Flex
          ml={3}
          flex="1"
          overflow="auto"
          minHeight="1px"
          flexDirection="column"
        >
          {/* Accounts */}
          {/* {visiblePanel === 0 && ( */}
          <AccountsPanel
            selectedAccountId={selectedAccountId}
            setSelectedAccountId={onAccountIdSelected}
            isHidden={visiblePanel !== 0}
            showPanel={showPanel}
          />
          {/* )} */}
          {/* / Accounts */}

          {/* <Separator direction="x" my={4} /> */}

          {/* Forms */}
          <FormsPanel
            selectedAccountId={selectedAccountId}
            setSelectedFromId={onFormIdSelected}
            selectedFormId={selectedFormId}
            isHidden={visiblePanel !== 1 || !selectedAccountId}
            showPanel={showPanel}
          />
          {/* Forms */}

          {/* <Separator direction="x" my={4} /> */}

          {/* Sections */}
          {visiblePanel === 2 && selectedFormId && (
            <SectionsPanel
              isFormDetailsLoading={isFormDetailsLoading}
              formDetails={formDetails}
              onSelectSectionItem={onSelectSectionItem}
              selectedSectionItems={selectedSectionItems}
              openCloseSection={openCloseSection}
              expandAll={expandAll}
              isCopyQuestion={isCopyQuestion}
              onMultiSelect={setSelectedSectionItems}
            />
          )}
          {/* / Sections */}
          {/* Questions */}
          {visiblePanel === 3 && selectedSectionItems && (
            <QuestionsPanel
              selectedSectionItem={selectedSectionItems[0]}
              selectQuestionItem={selectQuestionItem}
              selectedQuestionItem={selectedQuestionItem}
              allQuestionsInSection={allQuestionsInSection}
              isCopyQuestion={isCopyQuestion}
            />
          )}
          {/* / Questions */}

          {/* <Separator direction="x" my={4} /> */}
        </Flex>
      </Flex>
    </SlidePanel>
  );
}
