import React, { ReactElement, useState, useCallback, useMemo } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Table, SearchInput } from 'ui/patterns';
import { Button, Stack, Separator } from 'ui/components';
import * as Icon from 'assets/icons';
import { useCustomRecords } from 'hooks';
import AddEditCustomRecords from '../AddEditCustomRecords';
import useCustomRecordsColumns from './useCustomRecordsColumns';

type CustomRecordsProps = {
  selectedTable: any;
  toggleRecordsGrid: () => void;
};

// const pageLength = 100;

export default function CustomRecords({
  selectedTable,
  toggleRecordsGrid,
}: CustomRecordsProps): ReactElement {
  const history = useHistory<{ ID: number }>();
  const [showPanel, setShowPanel] = useState(false);
  const [record, setRecord] = useState(undefined);
  const sortItem = { sortKey: selectedTable?.Columns[0], sortOrder: 'asc' };
  const [columnsSearch, setColumnsSearch] = useState({}); // table header search
  const [currentSortItem, setCurrentSortItem] = useState(sortItem);
  const [keywordSearch, setKeywordSearch] = useState(''); //keyword search
  const [page, setPage] = useState(0);
  const [mode, setMode] = useState(''); //add or edit
  // let numberOfPages = 0;
  const { accountId } = useParams<{
    accountId: string;
    tabId?: string;
  }>();
  const tableId = selectedTable?.Id;
  const toggleAddPanel = () => {
    setShowPanel(false);
    setRecord(undefined);
  };

  const {
    data,
    fetchMore,
    canFetchMore,
    isLoading,
    isFetching,
    isFetchingMore,
  } = useCustomRecords(
    page,
    columnsSearch,
    currentSortItem,
    keywordSearch,
    parseInt(accountId),
    tableId,
    selectedTable?.Columns,
  );

  const columns = useCustomRecordsColumns(selectedTable?.Columns);

  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch],
  );

  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (
        currentSortItem.sortKey === item &&
        currentSortItem.sortOrder === 'asc'
      ) {
        sortOrder = 'desc';
      }
      setCurrentSortItem({
        sortKey: item,
        sortOrder,
      });
    },
    [setCurrentSortItem, currentSortItem],
  );

  const handleKeywordSearch = (value: string): void => {
    setKeywordSearch(value);
  };

  const handleReset = (): void => {
    setKeywordSearch('');
    setColumnsSearch({});
    setCurrentSortItem(sortItem);
  };

  const handleEditRecord = (row: any): void => {
    setRecord(row);
    setShowPanel(true);
    setMode('Edit');
  };
  const tableData = useMemo(() => {
    if (data) {
      return data
        .map((dataItem: any) => {
          if (dataItem) return dataItem.data;
        })
        .flat();
    }
  }, [data]);

  const totalItems = useMemo(() => {
    if (data && data[0]) {
      return data[0].recordsFiltered;
    }
    return 0;
  }, [data]);

  const onEndReached = () => {
    if (tableData) {
      if (canFetchMore && tableData?.length < totalItems) fetchMore();
    }
  };
  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Stack direction="x" gap={2} style={{ alignItems: 'center' }}>
          <Button
            variant="secondary"
            iconBefore={<Icon.ChevronLeft />}
            onClick={toggleRecordsGrid}
          >
            Back
          </Button>
          <Text
            fontSize={1}
            color="text.body"
            fontWeight="bold"
            sx={{ height: '20px' }}
          >
            {selectedTable?.TableName}
          </Text>
          <Separator direction="y" style={{ minHeight: '20px' }} />
          <Text fontSize={1} color="text.body" sx={{ height: '20px' }}>
            {tableData &&
              tableData.length > 0 &&
              `Showing ${tableData?.length || 0}
               of ${totalItems}`}
            {isFetching && '  Processing'}
          </Text>
        </Stack>
        <Stack direction="x" gap={2}>
          <SearchInput
            placeholder="Search Records"
            handleChange={handleKeywordSearch}
            value={keywordSearch}
          />
          <Button
            iconBefore={<Icon.Refresh title="Refresh" />}
            variant="secondary"
            onClick={handleReset}
          >
            Reset All
          </Button>
          <Button
            iconBefore={<Icon.Add title="Add" />}
            variant="primary"
            onClick={() => {
              setRecord(undefined);
              setShowPanel(true);
              setMode('Add');
            }}
          >
            Add
          </Button>
        </Stack>
      </Flex>

      <Table
        columns={columns}
        data={tableData ? tableData : undefined}
        isLoading={isLoading}
        headerUpdateCallBack={headerUpdateCallBack}
        handleSortChange={handleSortChange}
        sortedItem={currentSortItem}
        columnsSearch={columnsSearch}
        onRowDoubleClick={handleEditRecord}
        onRecordEditPress={handleEditRecord}
        masterTableId={selectedTable?.Id}
        onEndReached={onEndReached}
        isFetching={!!isFetchingMore}
      />

      <AddEditCustomRecords
        showPanel={showPanel}
        togglePanel={toggleAddPanel}
        record={record}
        selectedTable={selectedTable}
        accountId={parseInt(accountId)}
        tableId={tableId}
        mode={mode}
      />
    </>
  );
}
