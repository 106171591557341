import React from 'react';
import { CountryObj, optionType, CountryType } from '../types/countryType';
import { Flex } from 'rebass';

export function optionList(countryList: Array<CountryObj>): any {
  let options: Array<optionType> | undefined = [];
  options = countryList?.map((country: any) => ({
    value: country?.Id,
    label: (
      <Flex style={{ gap: '0.5ch' }}>
        <img
          alt="United States"
          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country?.Abbreviation}.svg`}
          style={{ width: '24px', height: 'auto' }}
        />
        <span>
          ({country?.ISDCode}) {country.Name}
        </span>
      </Flex>
    ),
    isdCode: country?.ISDCode,
    abbreviation: country?.Abbreviation,
    name: country?.Name,
  }));
  return options;
}

//getting the value for dropdown
export function getCountryObj(countryId: any, countryList: any) {
  if (countryList) {
    const country = countryList?.find((item: any) => {
      if (item?.Id === parseInt(countryId)) return item;
    });

    if (country) {
      const options = {
        value: country?.Id,
        label: (
          <Flex style={{ gap: '0.5ch' }}>
            <img
              alt="United States"
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country?.Abbreviation}.svg`}
              style={{ width: '24px', height: 'auto' }}
            />

            <span className="dark:text-white">{country?.ISDCode}</span>
          </Flex>
        ),
        isdCode: country?.ISDCode,
        abbreviation: country?.Abbreviation,
        name: country?.Name,
      };

      return options;
    } else {
      return {};
    }
  }
}

// formatting data to sned to api with adding twilio data as well.
export function formatData(
  data: any,
  accountId: any,
  twilioData: any,
  countryList: any,
  initialBracnhoutconditionId: number | undefined,
  initialWorkFlowId: number | undefined,
  initialPhnDetails:
    | Array<{
        phnType: number;
        phnNumber: number;
        countryId: number;
        id: number;
      }>
    | undefined,
): any {
  let TwilioAccountWorkFlow: {
    TwilioWorkFlowId: number;
    AccountId?: null | number;
  };
  let TwilioAccountBranchOutCondition: {
    TwilioBranchOutConditionsId: number;
    AccountId?: null | number;
  };

  if (accountId) {
    TwilioAccountWorkFlow = {
      TwilioWorkFlowId:
        data?.TwilioWorkFlowId && data?.TwilioWorkFlowId !== -1
          ? data?.TwilioWorkFlowId
          : null,
      AccountId: parseInt(accountId),
    };
    TwilioAccountBranchOutCondition = {
      TwilioBranchOutConditionsId:
        data?.TwilioBranchOutConditionsId !== '' &&
        data?.TwilioBranchOutConditionsId !== 0
          ? data?.TwilioBranchOutConditionsId
          : 1,
      AccountId: parseInt(accountId),
    };
  } else {
    TwilioAccountWorkFlow = {
      TwilioWorkFlowId:
        data?.TwilioWorkFlowId && data?.TwilioWorkFlowId !== null
          ? data?.TwilioWorkFlowId
          : null,
    };
    TwilioAccountBranchOutCondition = {
      TwilioBranchOutConditionsId:
        data?.TwilioBranchOutConditionsId !== '' &&
        data?.TwilioBranchOutConditionsId !== 0
          ? data?.TwilioBranchOutConditionsId
          : 1,
    };
  }
  const tempInitialPhnDetails = initialPhnDetails?.map(item => ({
    PhoneNumber:
      formattedPhoneNumber(item?.phnNumber, item?.countryId, countryList) ?? '',
    PhoneTypeId: item?.phnType && item?.phnType !== null ? item?.phnType : '',
    CountryId: item?.countryId || 1,
    IsDeleted: false,
    Id: item?.id,
  }));
  const updatedInitialPhnDetails = tempInitialPhnDetails?.sort(
    (a: any, b: any) => a?.CountryId - b?.CountryId,
  );
  const TwilioContactNumbers = data?.TwilioContactNumbers?.map((item: any) => ({
    Name: '',
    PhoneNumber:
      formattedPhoneNumber(item?.PhoneNumber, item?.CountryId, countryList) ??
      '',
    Description: '',
    CountryId: item?.CountryId || 1,
    AccountId: accountId ?? '',
    PhoneTypeId:
      item?.PhoneTypeId && item?.PhoneTypeId !== null ? item?.PhoneTypeId : '',
    Id: item?.Id ? item?.Id : 0,
    IsDeleted: item?.IsDeleted === true ? true : false,
  }));

  twilioData?.forEach((item: any) => {
    if (item?.Id?.toString() !== '0') {
      TwilioContactNumbers?.push({
        Name: '',
        PhoneNumber: item?.PhoneNumber ?? '',
        Description: '',
        CountryId: item?.CountryId || 1,
        AccountId: accountId ?? '',
        PhoneTypeId: item?.PhoneTypeId ?? '',
        Id: item?.Id ? item?.Id : 0,
        IsDeleted: true,
      });
    } else return;
  });
  const tempTwilio = TwilioContactNumbers?.map((item: any) => ({
    PhoneNumber: item?.PhoneNumber,
    PhoneTypeId: item?.PhoneTypeId,
    CountryId: item?.CountryId,
    IsDeleted: item?.IsDeleted,
    Id: parseInt(item?.Id),
  }));
  const updatedTemTwillio = tempTwilio?.sort(
    (a: any, b: any) => a.CountryId - b?.CountryId,
  );

  const matchingIds: any = [];
  updatedTemTwillio.forEach((obj1: any) => {
    updatedInitialPhnDetails?.forEach((obj2: any) => {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (
        keys1.length === keys2.length &&
        keys1.every(key => obj1[key] === obj2[key])
      ) {
        matchingIds.push(obj1.Id);
      }
    });
  });
  const updatedTwilioContactNumbers = TwilioContactNumbers?.filter(
    (item: any) => {
      if (!matchingIds?.includes(parseInt(item?.Id))) {
        return item;
      }
    },
  );

  delete data['RoutingSubGroup'];
  delete data['RoutingGroup'];
  delete data['Phone'];
  delete data['TwilioWorkFlowId'];
  delete data['TwilioBranchOutConditionsId'];
  const formattedData = {
    TwilioAccountWorkFlow:
      initialWorkFlowId === TwilioAccountWorkFlow?.TwilioWorkFlowId
        ? null
        : { ...TwilioAccountWorkFlow },
    TwilioAccountBranchOutCondition:
      initialBracnhoutconditionId ===
      TwilioAccountBranchOutCondition?.TwilioBranchOutConditionsId
        ? null
        : { ...TwilioAccountBranchOutCondition },
    TwilioContactNumbers:
      JSON.stringify(updatedInitialPhnDetails) ===
      JSON.stringify(updatedTemTwillio)
        ? []
        : [...updatedTwilioContactNumbers],
    EnableRecording: data?.EnableRecording,
    TwilioFlexPriority: parseInt(data?.TwilioFlexPriority),
    TwilioPAK: data?.TwilioPAK,
  };
  return formattedData;
}

const usPhnValidationMsg =
  'Please enter a valid phone number (e.g., xxx-xxx-xxxx)';

const otherPhnValidationMsg =
  'Please enter a valid phone number between 8 and 15 digits';

export function validationErrorMsgForPhone(
  country: any,
  countryId: number,
): string {
  if (hasUsFormat(country, countryId)) return usPhnValidationMsg;
  return otherPhnValidationMsg;
}

export function hasUsFormat(country: any[], id: number): boolean {
  const countryId = typeof id !== 'number' ? parseInt(id) : id;
  const foundCountry = country.find(elem => elem.value === countryId);
  return foundCountry ? foundCountry.label === '+1' : false;
}

export function countryOptions(country: any) {
  return country?.map((item: any) => ({
    value: item?.Id,
    label: item?.ISDCode,
    Abbreviation: item?.Abbreviation,
    Name: item?.Name,
  }));
}

export function isPhnIsInvalidOrNot(
  phnNumber: any,
  countryId: any,
  countryCodes?: any,
) {
  if (phnNumber === '' || phnNumber === undefined || phnNumber === null)
    return false;

  const isUsPhoneNumber = hasUsFormat(countryCodes, countryId);

  const phoneNumberLength = phnNumber.match(/\d/g)?.length;

  const isInvalidPhoneNumber =
    phoneNumberLength > 0 &&
    ((isUsPhoneNumber && phoneNumberLength < 10) ||
      (!isUsPhoneNumber && phoneNumberLength < 8));

  return isInvalidPhoneNumber;
}

export function isCountryCodeInUSFormat(
  prevCountryId: any,
  countryId: number,
  countryCode?: any,
): boolean {
  const isCurrentUSPhoneNumber = hasUsFormat(countryCode, countryId);
  const isPreviousUSPhoneNumber = hasUsFormat(countryCode, prevCountryId);

  return isCurrentUSPhoneNumber && !isPreviousUSPhoneNumber;
}

const usPhoneNumberFormat = /(\d{3})(\d{3})(\d{4}).*/;

export function formattedPhoneNumber(
  phoneNumber: any,
  countryId: any,
  countryList: any,
) {
  const countryCode = countryList?.find(
    (item: CountryType) => item?.Id === countryId,
  )?.ISDCode;
  if (phoneNumber) {
    let formattedPhone = phoneNumber.replace(/-/g, '');
    if (countryCode === '+1')
      formattedPhone = formattedPhone.replace(usPhoneNumberFormat, '$1-$2-$3');
    return formattedPhone;
  }
  return '';
}
